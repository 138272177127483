import React, {useContext} from "react";
import styled from "@emotion/styled";
import TechChips from "./TechChips";
import theme from "theme";
import {Typography, Grid} from "@mui/material";
import {default as SectionContainer} from "ui-components/SectionContainer";

const OuterContainer = styled.div`
	padding-top: 40px;
`;

const TitleContainer = styled(Grid)`
	padding-top: 20px;
	padding-bottom: 0px;
`;

// const Logo = styled.img`
//   height: 36px;
//   width: 36px;
//   width: auto;
// `;

const Title = styled(Typography)`
	font-weight: bold;
`;

const Divider = styled.hr`
	border: none;
	height: 2px;
	background-color: ${theme.palette.neutral.main};
	border-radius: 10px;
	margin: 20px 0;
`;

const BulletList = styled(Grid)`
	flex-direction: column;
	margin-bottom: 20px;
`;

const StyledLink = styled.a`
	text-decoration: none;
	color: inherit;

	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`;

const ProjectDetails = ({project, additionalContent}) => {
	const Content = (
		<>
			<TitleContainer container direction='row' alignItems='center' spacing={2}>
				<Grid item>
					<img
						src={project.imgLink}
						alt={project.name}
						height={36}
						width={36}
					/>
				</Grid>
				<Grid item>
					<Title variant='h1'>{project.name}</Title>
				</Grid>
			</TitleContainer>
			<Typography>{project.longDescription}</Typography>
		</>
	);

	return (
		<OuterContainer>
			<SectionContainer id={project.sectionId}>
				<TechChips technologies={project.tech} />
				{project.href ? (
					<StyledLink href={project.href}>{Content}</StyledLink>
				) : (
					Content
				)}
				<Divider />
				<BulletList spacing={1}>
					{project.bullets &&
						project.bullets.map((bullet) => (
							<Grid item xs={12} key={bullet}>
								<Typography
									sx={{
										display: "flex",
										alignItems: "center",
										fontWeight: "bold",
									}}
								>
									• {bullet}
								</Typography>
							</Grid>
						))}
				</BulletList>
				<img src={project.bigImgLink} alt={project.name} width='100%' />
				{additionalContent}
			</SectionContainer>
		</OuterContainer>
	);
};

export default ProjectDetails;
