import {Grid, Typography} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import theme from "theme";

const StyledGridContainer = styled(Grid)`
	padding: 20px 50px 0px 50px;
	height: fit-content;
	align-items: center;

	@media (max-width: 600px) {
		padding: 20px 20px 0px 20px;
	}
`;

const BlueOuterContainer = styled(Grid)`
	border: 1px solid ${theme.palette.primary.main};
	background-color: ${theme.palette.primary.main}80;
	border-radius: 100px;
	padding: 5px;
	margin-bottom: 20px;
	@media (max-width: 600px) {
		margin-bottom: 10px;
	}
`;

const BlueIconsContainer = styled(Grid)`
	border: 1px solid ${theme.palette.primary.main};
	background-color: ${theme.palette.primary.main}80;
	border-radius: 100px;
	padding: 10px 15px;
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
`;

const BlueInnerContainer = styled(Grid)`
	border: 1px solid ${theme.palette.primary.main};
	background-color: white;
	border-radius: 100px;
	padding: 5px 15px 5px 5px;
`;

const IconContainer = styled(Grid)`
	border: 1px solid ${theme.palette.primary.main};
	background-color: white;
	border-radius: 100px;
	height: 40px;
	width: 40px;
	cursor: pointer;
	&:hover {
		box-shadow: 0 0 5px ${theme.palette.primary.main};
		border: 2px solid ${theme.palette.primary.main};
	}
`;

const StyledTypography = styled(Typography)`
	font-weight: bold;
	margin: 0;
`;

const IconButton = ({iconPath, alt, href}) => {
	return (
		<a
			href={href}
			target='_blank'
			rel='noopener noreferrer'
			style={{textDecoration: "none"}}
		>
			<IconContainer item container justifyContent='center' alignItems='center'>
				<img src={iconPath} alt={alt} height={30} width={30} />
			</IconContainer>
		</a>
	);
};

const TopSection = () => {
	const LeftSide = () => {
		return (
			<BlueOuterContainer item>
				<BlueInnerContainer
					container
					justifyContent='space-between'
					alignItems='center'
				>
					<img
						src='/pictures/profile-pic.png'
						alt='Eric'
						height={38}
						width={38}
						style={{
							marginRight: 10,
						}}
					/>
					<StyledTypography sx={{fontSize: 20}}>
						Heyo! I&apos;m Eric. Welcome to my portfolio!
					</StyledTypography>
				</BlueInnerContainer>
			</BlueOuterContainer>
		);
	};

	const RightSide = () => {
		return (
			<Grid item>
				<BlueIconsContainer container>
					<IconButton
						iconPath='/icons/bi-linkedin.png'
						alt='LinkedIn'
						href='https://www.linkedin.com/in/eric-russell-1398b2125/'
					/>
					<IconButton
						iconPath='/icons/bi-instagram.png'
						alt='Instagram'
						href='https://www.instagram.com/ericsremote/'
					/>
					<IconButton
						iconPath='/icons/bi-producthunt.png'
						alt='Product Hunt'
						href='https://www.producthunt.com/@erussell4'
					/>
					<IconButton
						iconPath='/icons/bi-email.png'
						alt='Email'
						href='mailto:russell.eric6@gmail.com'
					/>
				</BlueIconsContainer>
			</Grid>
		);
	};

	return (
		<StyledGridContainer container justifyContent='space-between'>
			<LeftSide />
			<RightSide />
		</StyledGridContainer>
	);
};

export default TopSection;
