import React from "react";
import Marquee from "react-fast-marquee";
import styled from "@emotion/styled";
import {Typography} from "@mui/material";
import theme from "theme";

const CarouselContainer = styled.div`
	width: 100%;
	overflow: hidden;
`;

const Divider = styled.hr`
	border: none;
	height: 1px;
	background-color: ${theme.palette.neutral.main};
	border-radius: 10px;
	margin: 5px 0;
`;

const ImageCarousel = ({
	items,
	speed = 40,
	direction = "right",
	imageWidth,
	imageHeight,
}) => {
	return (
		<CarouselContainer>
			<Marquee speed={speed} direction={direction} gradient={false} autoFill>
				{items.map((item, index) => (
					<div key={index} style={{padding: "0 20px"}}>
						<Typography variant='body1'>{item.title}</Typography>
						<Divider />
						<img
							src={item.imgLink}
							alt={item.title}
							width={imageWidth}
							height={imageHeight}
						/>
					</div>
				))}
			</Marquee>
		</CarouselContainer>
	);
};

export default ImageCarousel;
