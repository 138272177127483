export const festivalDustAppMarquee = [
  {
    title: 'Home',
    imgLink: 'festival-dust/marquee/home.png',
  },
  {
    title: 'Group Home',
    imgLink: 'festival-dust/marquee/group-home.png',
  },
  {
    title: 'Festival Home',
    imgLink: 'festival-dust/marquee/festival-home.png',
  },
  {
    title: 'Schedule',
    imgLink: 'festival-dust/marquee/schedule.png',
  },
]

export const festivalDustWebMarquee = [
  {
    title: 'Home',
    imgLink: 'festival-dust/web-marquee/home.png',
  },
  {
    title: 'Festival Lineup',
    imgLink: 'festival-dust/web-marquee/lineup.png',
  },
  {
    title: 'Festival Schedule',
    imgLink: 'festival-dust/web-marquee/schedule.png',
  },
]