import React from "react";
import styled from "@emotion/styled";
import {Grid, Typography} from "@mui/material";
import SectionContainer from "ui-components/SectionContainer";
import BrowseSectionBlock from "./BrowseSectionBlock";
import {projects} from "data";

const Title = styled(Typography)`
	font-weight: bold;
	padding-bottom: 20px;
`;

const BrowseSection = ({refsMap}) => {
	return (
		<SectionContainer>
			<Title variant='h2'>Browse Projects</Title>
			<Grid container>
				{projects.map((project) => (
					<BrowseSectionBlock
						project={project}
						key={project.name}
						ref={refsMap[project.sectionId]}
					/>
				))}
			</Grid>
		</SectionContainer>
	);
};

export default BrowseSection;
