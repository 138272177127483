import BrowseSection from "components/BrowseSection";
import Bison from "components/projects/Bison";
import Farmshare from "components/projects/Farmshare";
import FestivalDust from "components/projects/FestivalDust";
import TopSection from "components/TopSection";
import React, {useRef} from "react";
import {createContext} from "react";
import {projects} from "data";

export const SectionContext = createContext({});
export const SectionProvider = SectionContext.Provider;

const Home = () => {
	const refsMap = useRef({});

	projects.forEach((item) => {
		if (item.sectionId && !refsMap.current[item.sectionId]) {
			refsMap.current[item.sectionId] = React.createRef();
		}
	});

	return (
		<>
			<TopSection />
			<BrowseSection refsMap={refsMap} />
			<FestivalDust ref={refsMap.current["festival-dust"]} />
			<Farmshare />
			<Bison />
		</>
	);
};

export default Home;
