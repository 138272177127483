import React from "react";
import { projects } from "data";
import ProjectDetails from "../ProjectDetails";

const Farmshare = () => {
  return (
    <ProjectDetails project={projects.find(project => project.name === 'Farmshare')} />
  )
}

export default Farmshare;
