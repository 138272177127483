import React, {useContext} from "react";
import styled from "@emotion/styled";
import {Grid, Typography} from "@mui/material";
import theme from "theme";

const Container = styled(Grid)`
	border: 1px solid ${theme.palette.neutral.medium};
	border-radius: 10px;
	padding: 10px;
	background-color: white;
	${(props) =>
		props.hasSection &&
		`
		cursor: pointer;
		&:hover {
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
		}
	`}
`;

const StyledImage = styled.img`
	padding-right: 10px;
`;

const Title = styled(Typography)`
	font-weight: bold;
	font-size: 20px;
`;

const Description = styled(Typography)`
	font-size: 12px;
`;

const Roles = styled(Typography)`
	font-size: 12px;
	font-weight: bold;
`;

const formatRoles = (roles) => {
	return roles.join(" · ");
};

const InfoColumn = ({project}) => {
	return (
		<Grid container direction='column'>
			<Grid item>
				<Title variant='h3'>
					{project.name} · {project.shortTimeFrame}
				</Title>
			</Grid>
			<Grid item>
				<Description>{project.shortDescription}</Description>
			</Grid>
			<Grid item>
				<Roles>{formatRoles(project.roles)}</Roles>
			</Grid>
		</Grid>
	);
};

const BrowseSectionBlock = ({project, ref}) => {
	// const sectionRefs = useContext(SectionContext);

	const scrollToItem = (id) => {
		console.log(ref);
		// const itemRef = refsMap.current[id];
		if (ref && ref.current) {
			console.log(ref);
			ref.current.scrollIntoView({behavior: "smooth"});
		}
	};

	// const scrollToSection = () => {
	// 	if (!project.sectionId) return;

	// 	const element = document.getElementById(project.sectionId);
	// 	if (element) {
	// 		const offset = 80;
	// 		const elementPosition =
	// 			element.getBoundingClientRect().top + window.scrollY;
	// 		const offsetPosition = elementPosition - offset;

	// 		window.scrollTo({
	// 			top: offsetPosition,
	// 			behavior: "smooth",
	// 		});

	// 		window.history.pushState({}, "", `#${project.sectionId}`);
	// 	}
	// };

	return (
		<Grid item md={6} xs={12} sx={{paddingX: 2, paddingY: 1}}>
			<Container onClick={scrollToItem} hasSection={!!project.sectionId}>
				<Grid container alignItems='center'>
					<Grid item>
						<StyledImage
							src={project.imgLink}
							alt='Festival Dust Icon'
							height={50}
							width={50}
						/>
					</Grid>
					<Grid item xs>
						<InfoColumn project={project} />
					</Grid>
				</Grid>
			</Container>
		</Grid>
	);
};

export default BrowseSectionBlock;
