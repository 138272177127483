import React, { useEffect, useState } from 'react'
import './App.css'
import Home from './pages/Home';

const App = () => {
  return (
    <>
      <Home />
    </>
  );
}

export default App
