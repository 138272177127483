import React from "react";
import styled from "@emotion/styled";
import {Grid, Typography} from "@mui/material";
import theme from "theme";

const ChipContainer = styled(Grid)`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
`;

const Chip = styled(Grid)`
	border: 2px solid ${theme.palette.primary.main};
	background-color: ${theme.palette.primary.main}33;
	border-radius: 100px;
	padding: 4px 14px;
`;

const ChipText = styled(Typography)`
	font-size: 14px;
	font-weight: bold;
`;

const TechChips = ({technologies}) => {
	return (
		<ChipContainer container>
			{technologies.map((tech) => (
				<Chip item key={tech}>
					<ChipText>{tech}</ChipText>
				</Chip>
			))}
		</ChipContainer>
	);
};

export default TechChips;
