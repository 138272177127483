

export const projects = [
  {
    name: "Festival Dust",
    sectionId: 'festival-dust',
    href: 'https://festivaldust.com',
    shortDescription: 'An app I built to access all music festival set times.',
    longDescription: 'An app to access all music festival lineups, set times, and schedules in one place.',
    shortTimeFrame: '2023 - Current',
    roles: ['Founder', 'Designer', 'Engineer'],
    tech: ['React', 'Flutter', 'NextJS', 'Dart', 'Firebase', 'NodeJS'],
    imgLink: '/project-icons/festival-dust.png',
    bigImgLink: '/festival-dust/main.png',
    bullets: [
      '5k+ users on the app',
      '3-5k+ web visitors monthly',
      '1M+ Google Impressions',
    ],
  },
  {
    name: "Farmshare",
    sectionId: 'farmshare',
    shortDescription: 'First engineering hire at startup striving to help farmers sell direct to consumer.',
    longDescription: 'First engineering hire on team of 6 to help farmers sell direct to consumer',
    shortTimeFrame: '2024',
    roles: ['Designer', 'Engineer'],
    tech: ['React', 'Javascript', 'NextJS', 'NodeJS', 'GraphQL', 'Typescript'],
    imgLink: '/project-icons/farmshare.png',
    bigImgLink: '/farmshare/main.png',
    bullets: [
      'Lead design for all apps built during my time there.',
      'Built scheduling SaaS platform for meat processors alongside one other engineer in <2 months.',
      'Designed and built marketing website for company.',
    ],
  },
  {
    name: "Bucket List App",
    shortDescription: 'Building bucket list app for a client.',
    shortTimeFrame: '2024 - Current',
    roles: ['Designer', 'Engineer'],
    tech: ['Flutter', 'Dart', 'Firebase'],
    imgLink: '/project-icons/bucket.png',
  },
  {
    name: "Houzz",
    shortDescription: 'SEO Team - Optimized their customer acquisition funnel increasing conversion by 40%.',
    shortTimeFrame: '2021 - 2022',
    roles: ['Engineer', 'SEO'],
    tech: ['React', 'Javascript', 'NodeJS', 'PHP'],
    imgLink: '/project-icons/houzz.png',
  },
  {
    name: "Bison Finance",
    sectionId: 'bison',
    shortDescription: 'An automated personal finance app integrated with Plaid.',
    shortTimeFrame: '2020 - 2022',
    roles: ['Designer', 'Engineer'],
    tech: ['React', 'Javascript', 'NodeJS', 'Express', 'MongoDB'],
    imgLink: '/project-icons/bison.png',
    bigImgLink: '/bison/main.png',
    bullets: [
      'Personal finance app integrated with Plaid - fully automated.',
      'Unlaunched - For now.',
    ],
  },
  {
    name: "Target",
    shortDescription: 'Rebuilt new alerting platform for Target.',
    shortTimeFrame: '2019 - 2021',
    roles: ['Engineer'],
    tech: ['React', 'Javascript', 'NodeJS', 'GoLang'],
    imgLink: '/project-icons/target.png',
  },
  {
    name: "Amazon",
    shortDescription: 'Intern shit - Created API to show cost visibility between internal teams.',
    shortTimeFrame: '2018',
    roles: ['Engineer'],
    tech: ['Java'],
    imgLink: '/project-icons/amazon.png',
  },
];
