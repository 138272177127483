import React from "react";

const {default: styled} = require("@emotion/styled");
const {default: theme} = require("theme");

const Container = styled.div`
	border-top: 1px solid ${theme.palette.neutral.medium};
	border-bottom: 1px solid ${theme.palette.neutral.medium};
	background-color: ${theme.palette.neutral.main}0D;
	padding: ${(props) => props.padding || "40px 50px"};

	@media (max-width: 600px) {
		padding: ${(props) => props.mobilePadding || "20px 20px"};
	}
`;

const SectionContainer = ({children, padding, mobilePadding}) => {
	return (
		<Container padding={padding} mobilePadding={mobilePadding}>
			{children}
		</Container>
	);
};

export default SectionContainer;
