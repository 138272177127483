import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#00E0FF',
    },
    secondary: {
      main: '#FFFFFF',
    },
    neutral: {
      main: '#484D52',
      medium: '#BEBEBE',
    },
    error: {
      main: '#E53935',
    },
  },
  typography: {
    allVariants: {
      color: '#2B2B2B'
    },
    fontFamily: `'Nunito', 'sans-serif'`,
    // fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.5rem',
    }
  }
})

export default theme
