import React from "react";
import { projects } from "data";
import ProjectDetails from "../ProjectDetails";

const Bison = () => {
  const bisonData = projects.find(project => project.name === 'Bison Finance');

  return (
    <ProjectDetails project={bisonData} />
  )
}

export default Bison;