import React from "react";
import styled from "@emotion/styled";
import { projects } from "data";
import theme from "theme";
import ImageCarousel from "../ImageCarousel";
import { festivalDustAppMarquee, festivalDustWebMarquee } from "data-marquees";
import ProjectDetails from "../ProjectDetails";
const {Typography} = require("@mui/material");

const Title = styled(Typography)`
	font-weight: bold;
`;

const Divider = styled.hr`
  border: none;
  height: 2px;
  background-color: ${theme.palette.neutral.main};
  border-radius: 10px;
  margin: 20px 0;
`;

const CarouselContainer = styled.div`
  margin: 0 -50px;
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    margin: 0 -20px;
  }
`;

const FestivalDust = ({ref}) => {
  const festivalDustData = projects.find(project => project.name === 'Festival Dust');

  const AdditionalContent = (
    <>
      <Title variant='h2'>The App</Title>
      <Divider />
      <CarouselContainer>
        <ImageCarousel items={festivalDustAppMarquee} imageWidth={200} />
      </CarouselContainer>
      <Title variant='h2' sx={{ marginTop: '40px' }}>The Website</Title>
      <Divider />
      <CarouselContainer>
        <ImageCarousel items={festivalDustWebMarquee} imageHeight={400} direction="left" />
      </CarouselContainer>
    </>
  )
	return (
    <div ref={ref}>
    <ProjectDetails project={festivalDustData} additionalContent={AdditionalContent} />
    </div>
	);
};

export default FestivalDust;
